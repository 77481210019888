.container {
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    [data-prefers-color='dark'] & {
        background-color: unset;
    }
    
    .side {
        flex: none;
        color: #666;
        .adm-side-bar-item-active{
            color: #000;
            font-weight: bold;
        }
    }

    .main {
        flex: auto;
        padding: 0 8px 8px;
        overflow-y: scroll;

        .title {
            padding: 16px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
        }

        .adm-list-item {
            padding: 0;
        }

        .adm-list-item-content {
            padding: 0;
        }
    }
}
