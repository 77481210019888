.meal-view {
    .operate {
        height: 60px;
        line-height: 40px;
        font-size: 18px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main {
        padding: 0 20px;
        overflow-y: auto;

        .item {
            margin-bottom: 20px;

            .item-title {
                font-size: 16px;
                height: 40px;
                line-height: 30px;
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;
            }

            .item-list {
                .item-cell {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;
                    margin: 6px;
                    padding: 6px;
                    border-bottom: 1px solid #eee;
                }
            }
        }
    }
}
