.details-view{
    .adm-list.adm-list-card{
        height: var(--height);
        overflow: auto;
        margin: 0 12px;
    }
    .tips{
        display: flex;
        height: 27px;
        line-height: 17px;
        justify-content: space-between;
        padding: 10px 20px 0;
    }
}
