.begin-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;

    h2 {
        font-size: 18px;
        text-align: center;
        border-bottom: 1px solid #eee;
        height: 50px;
        line-height: 30px;
        padding: 10px;
    }

    .main {
        flex-grow: 2;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        flex-direction: column;

        .table-name {
            width: 80px;
            height: 80px;
            background-color: #fadb14;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
        }

        .tips {
            line-height: 50px;
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-height: 200px;
            .adm-virtual-input-content{
                text-align: center;
            }
            .adm-virtual-input-placeholder{
                text-align: center;
            }
        }
    }
}
