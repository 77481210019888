.spec-view {
    .selected-image {
        position: absolute;
        top: -40px;
        left: 20px;
        border: 1px solid #eee;
    }

    .selected-info {
        height: 50px;
        margin-left: 100px;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }
}
