.ant-layout-sider-trigger {
    background-color: transparent !important;
}

/**
 * 重写选择的树样式
*/
.custom-tree {
    .ant-tree-treenode {
        padding: 6px 0 10px;

        &::before {
            border-radius: 6px;
        }
    }
}
