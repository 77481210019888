.cart {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 40px;
    position: relative;
    height: 50px;
    z-index: 100;

    .icon {
        position: absolute;
        top: -24px;
        left: 10px;
        z-index: 1;
    }

    .info {
        flex-grow: 2;
        height: 50px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 18px;
        background-color: #333;
        text-indent: 90px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    .btn {
        width: 90px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fadb14;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        color: #000;
        font-size: 18px;
    }
}
